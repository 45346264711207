import React, { useState } from "react";
import styles from './team-element.module.less';
import Modal from 'components/block-elements/modal/modal';
import AdminTeamsModalElement from 'components/block-elements/admin-team-elements-properties/admin-team-elements-properties';

const Teampropsement = (props) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [team, setTeam] = useState({
        name: '',
        country: '',
        id: null,
        sport: '',
        displayName: '',
        sportId: '',
        image: '',
        countryCode: '',
        newImageFile: null,
        newImageId: null
    });

    const openModal = () => {
        setTeam(props);
        setIsOpen(true);
    }
    
    const closeModal = (event) => {
        setIsOpen(false);
    }

    return (<>
    <div>
        <div onClick={openModal} className={styles.mainBox}>
            {props.id && props.id.length > 0 && <img className={styles.teamImage} key={props.id} id={props.id} src={props.image}></img>}
            <div className={styles.infoContainer}>
                {props.name && props.name.length > 0 && <div className={styles.displayComponent}>Team name: {props.name}</div>}
                {props.country && props.country.length > 0 && <div className={styles.displayComponent}>Team country: {props.country}</div>}
                {props.id && props.id.length > 0 && <div className={styles.displayComponent}>Team Id: {props.id}</div>}
                {props.sport && props.sport.length > 0 && <div className={styles.displayComponent}>Team sport name: {props.sport}</div>}
            </div>
        </div>
        {modalIsOpen && 
        <Modal setIsOpen={closeModal}>
            <AdminTeamsModalElement refresh={() => props.refresh()} closeModal={closeModal} {...team}/>
        </Modal>}
    </div>
    </>)
}

export default Teampropsement;