import React, { useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import ImagesService from 'services/image-services';
import CompetitirService from 'services/competitor-service';
import styles from './admin-team-elements-properties.module.less';
import Modal from "../modal/modal";
import VenueMainBlock from "../venue-main-block/venue-main-block";

const AdminTeamElementsProperties = (props) => {
    const [newImage, setNewImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [team, setTeam] = useState({
        name: '',
        country: '',
        id: null,
        sport: '',
        displayName: '',
        sportId: '',
        image: '',
        countryCode: '',
        newImageFile: null,
        formation: '',
        newImageId: null,
        venueId: '',
        venueName: '',
        venueCapacity: 0,
        venueCityName: '',
        venueCountry: '',
        venueMapCoordinateLat: '',
        venueMapCoordinateLong: ''
    });



    const [isModalShow, setIsModalShow] = useState(false);

    useEffect(() => {
        setTeam(props);
    },[])

    const handleChange = (event) => {
        setTeam({...team, newImageFile: event.target.files });
        setNewImage(URL.createObjectURL(event.target.files[0]))
    }

    const changeHandlerName = (event) => {
        setTeam({...team, name: event.target.value });
    };

    const changeHandlerCountry = (event) => {
        setTeam({...team, country: event.target.value });
    }

    const changeHandlerDisplayName = (event) => {
        setTeam({...team, displayName: event.target.value });
    }

    const changeHandlerFormation = (event) => {
        setTeam({ ...team, formation: event.target.value })
    }

    const uploadTeamImage = async() => {
        let result = null;
        const fd = new FormData();

        fd.append('files', team.newImageFile[0], team.name);
        await ImagesService.UploadNewFile(fd, 3, team.name)
        .then(res => {
            result = res.data;
            setTeam({ ...team, newImageId: res.data });
        }).catch(err => toast.error("Image upload faild"));  
        
        return result;
    }

    const openModal = () => {
        setIsModalShow(true);
    }

    const ccVenue = (newVenue) => {
        setTeam({...team, venueId: newVenue.id, venueName: newVenue.name, venueCapacity:  newVenue.capacity, venueCityName: newVenue.cityName, venueCountry: newVenue.country});
    }

    const save = async() => {
        setLoading(true);
        const uploadTeam = {
            name: '',
            country: null,
            id: null,
            displayName: null,
            image: '',
            countryCode: null,
            newImageId: null,
            venueId: '',
            formation: ''
        };

        if(team.newImageFile != null) {
            uploadTeam.newImageId = await uploadTeamImage();      
        }
        
        uploadTeam.country = team.country;
        uploadTeam.countryCode = team.countryCode;
        uploadTeam.displayName = team.displayName;
        uploadTeam.id = team.id;
        uploadTeam.formation = team.formation;
        uploadTeam.name = team.name;
        uploadTeam.venueId = team.venueId;

        await CompetitirService.changeCompetitorData(uploadTeam)
        .then(res => {
            toast.success('Data changed successfully!');
            props.refresh();
        }).catch(err => {
            toast.error('Changed Faild!');
        })
        setLoading(false);
    }

    return(
        <>
            <div className={styles.buttonContainer}>
                <button className={styles.closeButton} onClick={props.closeModal}>X</button>
            </div>
            <div className={styles.mainContainer}>
                <div className={styles.childContainer}>
                    <span>Team Id</span>
                    <input
                        name='Team Id'
                        value={team.id}
                        readOnly
                        className={styles.input}
                        type='text'>
                    </input>
                    <span>Team Name</span>
                    <input
                        name='Team Name'
                        value={team.name}
                        onInput={changeHandlerName}
                        className={styles.input}
                        type='text'>
                    </input>
                    <span>Team Country</span>
                    <input
                        name='Team Country'
                        value={team.country}
                        onInput={changeHandlerCountry}
                        className={styles.input}
                        type='text'>
                    </input>
                    <span>Team Display Name</span>
                    <input
                        name='Team DisplayName'
                        value={team.displayName}
                        onInput={changeHandlerDisplayName}
                        className={styles.input}
                        type='text'>
                    </input>
                    <span>Team Sport</span>
                    <input
                        name='Team DisplayName'
                        value={team.sport}
                        readOnly
                        className={styles.input}
                        type='text'>
                    </input>
                    <span>Default formation</span>
                    <input onInput={(e) => changeHandlerFormation(e)} type='text' value={team.formation} name="Formation"></input>
                    <span>Venue</span>
                    <div className={styles.venue} onClick={openModal}>
                        <div>
                            <span>Name: </span> 
                            <span>{team?.venueName}</span> 
                        </div>
                        <div>
                            <span>Capacity: </span> 
                            <span>{team?.venueCapacity}</span> 
                        </div>
                        <div>
                            <span>Country: </span>
                            <span>{team?.venueCountry}, {team?.venueCityName}</span> 
                        </div>
                    </div>
                    <span>Team Sport Id</span>
                    <input
                        name='Team DisplayName'
                        value={team.sportId}
                        readOnly
                        className={styles.input}
                        type='text'>
                    </input>
                    <span>Image</span>
                    <img className={styles.modalTeamImage} src={newImage ?? team.image}></img>
                    <input accept="image/*" className={styles.inputFile} type="file" onChange={handleChange} />
                    {!loading && <button disabled={loading} onClick={save} className={styles.saveButton}>Save</button>}
                    <Toaster position='top-left' reverseOrder={true} />
                </div>
            {isModalShow && <Modal zIndex={15} setIsOpen={() => setIsModalShow(false)}>
                <VenueMainBlock closeModel={() => setIsModalShow(false)} setVenue={(val) => ccVenue(val)}  isFroIdReturn={true}/>
            </Modal>}
            </div>
        </>
    )
}

export default AdminTeamElementsProperties;