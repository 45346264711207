import React from 'react';
import AdminTeams from 'components/block-elements/admin-teams/admin-teams';
import PageLayout from 'components/pageLayout';
import { Helmet } from 'react-helmet';

const TeamsBlock = (props) => {
    const searchParams = new URLSearchParams(props.location.search);
    const searchText = searchParams.get('searchText')
    const page = searchParams.get('page')
    return (
    <>
    <Helmet
    title='Admin Panel Teams'>

    </Helmet>
        <PageLayout>
            <AdminTeams searchText={searchText} page={page} searchParam={props.location.search}/>
        </PageLayout>
    </>
    );
};

export default TeamsBlock;
