import axios from 'components/helpers/axios';

const url = '/api/competitor';

class CompetitorService {
    getAllFilterCompetitors(model) {
        return axios.post(`${url}/getAllFilterCompetitors`, model);
    }

    changeCompetitorData(model) {
        return axios.put(`${url}/changeCompetitorData`, model);
    }

    getCompetitorsByTournamentId(tournamentId) {
        return axios.get(`${url}/getCompetitorsByTournamentId?tournamentId=${tournamentId}`);
    }

}

const singleton = new CompetitorService();
export default singleton;
