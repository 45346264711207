import React, {useState, useEffect} from "react";
import CompetitorService from 'services/competitor-service';
import styles from './admin-teams.module.less';
import { navigate } from 'gatsby'
import TeamElement from 'components/block-elements/team-element/team-elements';
import Loader from 'components/block-elements/loader-css/loader-css';

const AdminTeamsBlock = (props) => {
    const [search, setSearch] = useState(props.searchText);
    const [pagesCount, setPagesCount] = useState(null);
    const [loading, setLoading] = useState(false);

    const [filterModel, setFilterModel] = useState({
        searchText: props.searchText,
        page: props.page > 0 ? props.page - 1 : 0
    });

    const [teamsModel, setTeamsModel] = useState(null);

    useEffect(() => {
        getTeamsFilterData()
    }, []);

    useEffect(() => {
        getTeamsFilterData()
    }, [filterModel]);

    const getTeamsFilterData = async() => {
        setLoading(true);
        await CompetitorService.getAllFilterCompetitors(filterModel)
        .then(res => {
            setTeamsModel(res.data.data);
            setPagesCount(Array.from(Array(Math.ceil(res.data.total / 10)).keys()));

        }).catch(err => {
        })
        setLoading(false);
    }

    const searchClick = () => {
        setFilterModel({...filterModel, page: 0, searchText: search})
        let searchParams = new URLSearchParams(props.searchParam);
        searchParams.delete('searchText');
        searchParams.delete('page');
        searchParams.append("searchText", search);
        searchParams.append("page", 1);
        navigate(`/admin-panel/teams?${searchParams.toString()}`);
    }

    const changeHandlerSearch = (event) => {
        setSearch(event.target.value);
    };

    const clearSearch = () => {
        setFilterModel({ ...filterModel, page: 0, searchText: null });
        setSearch('');
        navigate(`/admin-panel/teams`);
    }

    const chagePage = async (event) => {
        let searchParams = new URLSearchParams(props.searchParam);
        searchParams.delete('page');
        searchParams.append("page", parseInt(event.target.name) + 1);
        navigate(`/admin-panel/teams?${searchParams.toString()}`);
        setFilterModel({ ...filterModel, page: event.target.name });
    };

    return(
        <div id="admin-teams-main-element" className={styles.mainContainer}>
            {loading && <Loader></Loader> }
                <div className={styles.searchBlock}>
                    <input
                    name='searchText'
                    placeholder='Search by (Ids and Names: Teams)'
                    value={search}
                    onInput={changeHandlerSearch}
                    className={styles.input}
                    type='text'></input>
                    <button disabled={search == null || search.length < 1} className={styles.searchButton} onClick={() => searchClick()}>
                    Search
                    </button>
                    <button disabled={search == null || search.length < 1} onClick={() => clearSearch()}>Clear</button>
                </div>
            {!loading && <div>
                {teamsModel?.map(el => <TeamElement refresh={getTeamsFilterData} key={el.id} {...el}></TeamElement>)}

            <div className={styles.flexButtonGroup}>
                <div className={styles.buttonsGroups}>
                {pagesCount &&
                pagesCount.length > 0 &&
                pagesCount.map((el, i) => {
                    return (
                    <button
                        className={`${el == filterModel.page ? styles.activePagButton : ''} ${styles.button}`}
                        name={el}
                        key={el}
                        onClick={chagePage}>
                        {el + 1}
                    </button>
                    );
                })}
            </div>
            </div>
            </div>}
        </div>
    )
}

export default AdminTeamsBlock;